<template>
  <form-create
      ref="form"
      :components="components"
      :options="{labelWidth:'120px'}"
      v-model="form"
      @update="handleUpdate"
  >
    <template slot="before">
      <slot name="before"></slot>
    </template>
    <template slot="after">
      <slot name="after">
        <div style="text-align: center">
          <el-button type="primary" size="small" @click="goBack" :disabled="loading">取消</el-button>
          <el-button type="primary" size="small" @click="handleSubmit" :disabled="loading">提交</el-button>
        </div>
      </slot>
    </template>
  </form-create>
</template>

<script>
import FormCreate from "@/components/com/FormCreate";
import tool from "@/Utils/tool";
export default {
  name: "teachRegister",
  components: {FormCreate},
  model: {
    prop: 'formData',
    event: 'change'
  },
  props:{
    formData:{
      type:Object,
      default(){return {}; },
    },
    type:''
  },
  watch:{
    formData:{
      deep:true,
      immediate:true,
      handler(v){
        if(!tool.obj_equal(v , this.form)){
          this.initForm(v , true);
        }
      }
    },
    userInfo:{
      immediate:true,
      deep:true,
      handler(v,o){
        if(!(this.type === 'apply' && v.type !== 2) && !tool.obj_equal(v,o)){
          this.initForm(v);
        }
      },
    }
  },
  data(){
    var _this = this;
    return {
      form:{},
      loading:false,
      components:[
        {name:'form-item',props:{prop:'headimg',label:'头像' , type:'upload',accept:'image' , listType:'picture-card',required:true,limit:1}},
        {name:'el-row' ,components:[
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'realname',label:'真实姓名',required:true}},
                {name:'form-item',props:{prop:'nickname',label:'昵称'},callback(c){
                    c.props.required = !((_this.form.id||0)>0);
                  }},
                {name:'form-item',props:{prop:'password',label:'登录密码',type:'password',required:true,showPassword:true},hidden:()=>{
					return _this.userInfo.type == 1;
				}},
              ]},
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'phone',label:'手机号码'},callback(c){
                    c.props.disabled = (_this.form.id||0)>0;
                    c.props.required = !((_this.form.id||0)>0);
                }},
                {name:'form-item',props:{prop:'email',label:'邮箱'}},
                {name:'form-item',props:{prop:'qq_num',label:'qq号码'}},
              ]}
          ]
        },
        {name:'form-item',props:{prop:'address',label:'现居地址'}},
        {name:'form-item',props:{prop:'birth',label:'出生年月', rules:[ { validator(rule, value, callback){if(value && (new Date(value)).getDate() !== parseInt(value.split('-')[value.split('-').length - 1])){callback(new Error('出生年月不正确'))}}, trigger: 'change'}]},components:[
            {name:'el-row' ,components:[
                {name:'el-col',props:{span:6},components:[
                    {name:'form-item',props:{prop:'birth_year',label:'出生年',itemType:0,type:'select'},callback(d){d.props.data = _this.dates.years;}},
                  ]},
                {name:'el-col',props:{span:6,offset:1},components:[
                    {name:'form-item',props:{prop:'birth_month',label:'出生月',itemType:0,type:'select'},callback(d){d.props.data = _this.dates.months;}}
                  ]},
                {name:'el-col',props:{span:6,offset:1},components:[
                    {name:'form-item',props:{prop:'birth_day',label:'出生日',itemType:0,type:'select'},callback(d){d.props.data = _this.dates.days;}}
                  ]},
            ]},
          ]
        },
        {name:'form-item',props:{prop:'idcard',label:'身份证件号码',required:true , rules:[ { validator(rule, value, callback){if(value &&!(/(^\d{15}$)|(^\d{17}([0-9]|X|x)$)/.test(value))){callback(new Error('身份证件号码格式不正确'))}}, trigger: 'change'}]},callback(c){
            // c.props.disabled = (_this.form.id||0)>0;
          }},
        {name:'el-row' ,components:[
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'idcardImgFace',label:'身份证照' , type:'upload',accept:'image' , listType:'picture-card',limit:1,notice:"请上传身份证人像面图片"},style:{marginBottom:0},callback(c){
                    // c.props.disabled = (_this.form.id||0)>0;
                  }},
              ]},
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'idcardImgBack',label:'身份证照' , type:'upload',accept:'image' , listType:'picture-card',limit:1,notice:"请上传身份证国徽面图片"},style:{marginBottom:0},callback(c){
                    // c.props.disabled = (_this.form.id||0)>0;
                  }},
              ]},
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'teachImg',label:'教师资格证' , type:'upload',accept:'image' , listType:'picture-card',limit:1,notice:"请上传教师资格证的图片"},style:{marginBottom:0},callback(c){
                    // c.props.disabled = (_this.form.id||0)>0;
                  }},
              ]}
          ]
        },
        { name:'divider-box' ,
          props:{borderColor:'#000',contentPosition:'left',position:80 , title:'教育经验',bodyStyle:{margin:'22px 20px 0'}},
          slots:{
            default:[
              {name:'form-item',props:{
                  prop:'education',itemType:2,labelPosition:'left'
                  ,columns:[
                    {prop:'school',label:'学校' , align:'center'},
                    {prop:'school_date',label:'学习时间',type:'monthrange',startField:'start_time',endField:'end_time'},
                  ],
                }
              },
            ]
          },
        },
        { name:'divider-box' ,
          props:{borderColor:'#000',contentPosition:'left',position:80 , title:'工作经验',bodyStyle:{margin:'22px 20px 0'}},
          slots:{
            default:[
              {name:'form-item',props:{
                  prop:'work_experience',itemType:2,labelPosition:'left',columns:[
                    {prop:'company',label:'所在公司'},
                    {prop:'position',label:'公司职位'},
                  ],
                }
              },
            ]
          }
        },
        {name:'form-item',props:{prop:'description',label:'教师简介',type:'editor',editorConfig:{maximumWords:500,toolbars:[
                [
                  'source', //源代码
                  'undo', //撤销
                  'redo', //重做
                  'bold', //加粗
                  'emotion', //表情
                  'spechars', //特殊字符
                  'justifyleft', //居左对齐
                  'justifyright', //居右对齐
                  'justifycenter', //居中对齐
                ]
              ]}}},
        {name:'form-item',props:{
            prop:'sex',label:'性别',type:'radio',defaultValue:'保密',data:[
              {label:'男',value:'男'},
              {label:'女',value:'女'},
              {label:'保密',value:'保密'},
            ]
          }
        },
      ],
      dates:{
        years:[],
        months:[],
        days:[],
      }
    }
  },
  methods:{
    routeUpdate(){
      this.initForm(Object.assign({}, (this.userInfo || {}).id ? this.userInfo : {}, this.$route.params , this.$route.query ));
      if(this.type === 'apply'){
        if(this.userInfo.type !== 2){
          this.$http.get("/xapi/user/teachApplyInfo").then(({data})=>{
            this.initForm({ ...data.apply_params });
          }).catch();
        }else{
          this.$handleMessage("你已经是老师了！",'success',()=>{
            this.$routerGo(-1,{name:"me"})
          })
        }
      }
    },
    initForm(obj , isForce = false){
      console.log(obj,"obj")
      let {password,birth , ...data} = obj || {};
      // console.log(password,"passwordpassword")
      let births = (birth || '').split('-')
      this.initDates(births[0] || 0,births[1] || 0);
      let $password = password ? tool.decrypt(password, 'password') : '';
      let form = {};
      if(isForce){
        form = Object.assign({}, data , {password:$password,birth,birth_year:births[0] || '',birth_month:births[1] || '',birth_day:births[2] || ''} )
      }else{
        form = Object.assign({},this.form , data , {password:$password,birth,birth_year:births[0] || '',birth_month:births[1] || '',birth_day:births[2] || ''} )
      }
      if(tool.obj_equal(form , this.form) === false){
        this.form = {...form};
      }
    },
    handleUpdate({prop}){
      if(prop && ['birth_year','birth_month'].indexOf(prop) > -1){
        this.initDates(this.form.birth_year,this.form.birth_month);
        if(this.form.birth_day && this.dates.days.indexOf(this.form.birth_day) === -1){this.form.birth_day = null;}
      }
      if(this.form.birth_year && this.form.birth_month && this.form.birth_day){
        this.form.birth = [this.form.birth_year , this.form.birth_month , this.form.birth_day].join('-')
      }else{
        this.form.birth = '';
      }
      this.$emit('change' , this.form)
    },
    goBack(){
      this.$router.back()
    },
    initDates(year , month){
      year = year || this.form.birth_year || 0
      month = month || this.form.birth_month || 0
      //获取年
      let date = new Date()
      let years = [];
      let current_year = date.getFullYear()
      for (let i = 0; i < 100; i++) {
        let y = current_year - i
        years.push(y+"")
      }
      let months = [];
      //获取月
      for (let i = 1; i < 13; i++) {
        if (i < 10) {
          months.push("0" + i)
        } else {
          months.push(i+"")
        }
      }
      let days = [];
      let day = new Date(year, parseInt(month), 0).getDate()
      for (let i = 1; i <= day; i++) {
        if (i < 10) {
          days.push("0" + i)
        } else {
          days.push(i+"")
        }
      }
      this.dates = {years ,months,days};
    },
    validate(callback , isOnlyField = false){
      return this.$refs.form.validate(callback , isOnlyField);
    },
    handleSubmit(){
      this.loading = true;
      this.validate((form , valid , errors)=>{
        if(valid){
          // this.form.idcardImg[0]=this.form.idcardImg1
          // this.form.idcardImg[1]=this.form.idcardImg2
          // this.$http.post('/xapi/user/updateTeach' , {...this.form}).then(({data,msg})=>{
          this.$http.post('/xapi/user.user/updateTeach' , {...this.form}).then(({data,msg})=>{
            this.$store.commit('SET' , {key:'userInfo' ,value:{...this.userInfo,refresh:true}})
            this.$handleMessage(msg || '教师资料更新成功' , 'success' ,()=>{this.$routerGo(-1,{name:"me"})});
          }).finally(()=>{
            this.loading = false;
          });
        }else if(errors.length > 0){
          this.loading = false;
          this.$handleMessage(errors[0].message , 'error');
        }else{
          this.loading = false;
        }
      } , true);
    }
  }
}
</script>

<style scoped>

</style>